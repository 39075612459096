.dropzone {
    flex-direction: column;
    height: 180px !important;
    justify-content: center;
    display: flex;
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
}

.selected-file-wrapper {
    text-align: center;
}

.selected-file {
    color: #000;
    font-weight: bold;
}